import {
  type CreateOptions,
  type Protocol,
  type ProtocolID,
  type Transport,
  ActionCableConsumer,
  type Cable,
} from "@anycable/core"
import { ProtobufEncoderV2 } from "@anycable/protobuf-encoder"
import { createCable } from "@anycable/web"
import SentryReact from "./sentry_client"

export let cable: Cable | null = null
export let consumer: ActionCableConsumer | null = null

if (!import.meta.env.SSR) {
  const absoluteWSUrl = (path: string) => {
    if (path.match(/wss?:\/\//)) return path

    if (typeof window !== "undefined") {
      const proto = window.location.protocol.replace("http", "ws")

      return `${proto}//${window.location.host}${path}`
    }

    return path
  }

  const fetchMeta = (doc: Document, key: string) => {
    const element = doc.head.querySelector(`meta[name='any-cable-${key}']`)

    if (element) {
      return element.getAttribute("content")
    }

    return null
  }

  const wsUrl = typeof document !== "undefined" ? absoluteWSUrl(fetchMeta(document, "url") || "") : ""
  const wsOpts: Partial<CreateOptions<ProtocolID | Protocol>> = {
    protocol: "actioncable-v1-ext-protobuf",
    protocolOptions: {
      pongs: true,
    },
    encoder: new ProtobufEncoderV2(),
    tokenRefresher: async (transport: Transport) => {
      try {
        const response = await fetch("/cable-token")
        const data = await response.json()

        transport.setURL(data.url)
      } catch (e) {
        console.error("Failed to refresh token", e)
        SentryReact.captureException(e)
      }
    },
  }

  cable = createCable(wsUrl, wsOpts)

  // @ts-expect-error ActionCableConsumer accepts a Cable but the constructor type is missing it and class constructors
  // cannot be augmented
  consumer = new ActionCableConsumer(cable)
}

export default cable
