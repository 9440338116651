interface VATPatterns {
  [index: string]: RegExp
}

interface VATNumbers {
  [index: string]: string
}

export const EUCountries = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
]

const VATPatternsEU: VATPatterns = {
  AT: /^ATU[0-9]{8}$/, // Austria
  BE: /^(BE0|BE1)[0-9]{9}$/, // Belgium
  BG: /^BG[0-9]{9,10}$/, // Bulgaria
  CY: /^CY[0-9]{8}[A-Z]$/, // Cyprus
  CZ: /^CZ[0-9]{8,10}$/, // Czech Republic
  DE: /^DE[0-9]{9}$/, // Germany
  DK: /^DK[0-9]{8}$/, // Denmark
  EE: /^EE[0-9]{9}$/, // Estonia
  ES: /^ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])$/, // Spain
  FI: /^FI[0-9]{8}$/, // Finland
  FR: /^FR[A-Z0-9]{2}[0-9]{9}$/, // France
  GR: /^EL[0-9]{9}$/, // Greece
  HR: /^HR[0-9]{11}$/, // Croatia
  HU: /^HU[0-9]{8}$/, // Hungary
  IE: /^IE([0-9][A-Z][0-9]{5}|[0-9]{7}[A-Z]?)[A-Z]$/, // Ireland
  IT: /^IT[0-9]{11}$/, // Italy
  LT: /^LT([0-9]{9}|[0-9]{12})$/, // Lithuania
  LU: /^LU[0-9]{8}$/, // Luxembourg
  LV: /^LV[0-9]{11}$/, // Latvia
  MT: /^MT[0-9]{8}$/, // Malta
  NL: /^NL[0-9]{9}B[0-9]{2}$/, // Netherlands
  PL: /^PL[0-9]{10}$/, // Poland
  PT: /^PT[0-9]{9}$/, // Portugal
  RO: /^RO[1-9][0-9]{1,9}$/, // Romania
  SI: /^SI[0-9]{8}$/, // Slovenia
  SK: /^SK[0-9]{10}$/, // Slovakia
  XI: /^XI([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})$/, // Northern Ireland
}

const VATPatterns: VATPatterns = {
  ...VATPatternsEU,
  AU: /^[0-9]{11}$/, // Australia
  GB: /^GB([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})$/, // United Kingdom
  NZ: /^[0-9]{8,9}$/, // New Zealand
}

// 'SE': /^SE[0-9]{10}01$/,                                        // Sweden

const VATExamples = [
  "DK99999999",
  "GER99999999",
  "PL9999999999",
  "FR99999999999",
  "FI99999999",
  "ESX9999999X",
  "NL999999999B99",
  "BE999999999",
  "IE9X99999X",
  "GB999999999",
  "GB999999999999",
  "GBGD999",
  "GBHA999",
  "DE999999999",
  "ATU99999999",
  "SE999999999901",
  "IT99999999999",
  "CZ12345678",
  "CZ123456789",
  "CZ1234567890",
]

const VATNumbers: VATNumbers = {
  AT: "ATU12345678",
  AU: "01 234 567 890",
  BE: "BE1234567890/BE0123456789",
  BG: "BG123456789, BG1234567890",
  CH: "CHE-123.456.789 MWST, TVA, IVA",
  CY: "CY12345678X",
  CZ: "CZ12345678, CZ123456789, CZ1234567890",
  DE: "DE123456789",
  DK: "DK12345678",
  EE: "EE123456789",
  ES: "ESX1234567X",
  FI: "FI12345678",
  FR: "FR12345678901",
  GB: "GB123456789, GB123456789012, GBGD123 or GBHA123",
  GR: "EL123456789",
  HR: "HR12345678901",
  HU: "HU12345678",
  IE: "IE1X34567X",
  IT: "IT12345678901",
  LT: "LT123456789, LT123456789012",
  LU: "LU12345678",
  LV: "LV12345678901",
  MT: "MT12345678",
  NL: "NL123456789B12",
  NO: "SE123456789012",
  NZ: "12345678, 123456789",
  PL: "PL1234567890",
  PT: "PT123456789",
  RO: "RO1234567890",
  SE: "SE123456789012",
  SI: "SI12345678",
  SK: "SK1234567890",
}

const invalidGlnExamples = [
  "0000000000000",
  "1111111111111",
  "2222222222222",
  "3333333333333",
  "4444444444444",
  "5555555555555",
  "6666666666666",
  "7777777777777",
  "8888888888888",
  "9999999999999",
]

const EmailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const NumberOrEmptyRegex = /^\d*$/
const PhoneRegex = /^\+?\d+$/
const ShoppingItemTypeRegex = /^[^>]+( > [^>]+)*$/
const LinkRegex = /^.*(\.[^/.]{2,})+(\/.*)*$/
const cardMonthRegex = /^0[1-9]|1[0-2]$/
const cardYearRegex = /^\d{2}$/
const cardCvcRegex = /^\d{3,4}$/

const cleanEinvoiceField = (value?: string | number) => {
  if (value) {
    return value.toString().replace(/\D/g, "")
  }

  return ""
}

const validateOrgID = (orgId: string, invoiceCountry: string) => {
  if (invoiceCountry === "DK") {
    if (
      !(orgId.length === 8 || (orgId.startsWith("DK") && orgId.length === 10)) ||
      cleanEinvoiceField(orgId).length !== 8
    ) {
      return false
    }
  } else if (invoiceCountry === "NO") {
    if (orgId.length !== 9 || cleanEinvoiceField(orgId).length !== 9) {
      return false
    }
  } else if (invoiceCountry === "SE") {
    if (
      !(orgId.length === 10 || (orgId.includes("-") && orgId.length === 11)) ||
      cleanEinvoiceField(orgId).length !== 10
    ) {
      return false
    }
  } else if (invoiceCountry === "FI") {
    if (
      !(
        orgId.length === 9 &&
        orgId.includes("-") &&
        cleanEinvoiceField(orgId.split("-")[0]).length == 7 &&
        cleanEinvoiceField(orgId.split("-")[1]).length == 1
      )
    ) {
      return false
    }
  }

  return true
}

const validateGlnNumber = (glnNumber: string, invoiceCountry: string, admin?: boolean) => {
  if (invoiceCountry == "FI") {
    if (glnNumber.length > 35 || glnNumber.length == 0) {
      return false
    }

    return true
  }

  if (invoiceCountry == "DK") {
    if (admin && glnNumber.length == 0) {
      return true
    } else if (
      glnNumber.length !== 13 ||
      cleanEinvoiceField(glnNumber).length !== 13 ||
      invalidGlnExamples.includes(glnNumber)
    ) {
      return false
    }

    return true
  }

  // NO, SE
  if (glnNumber.length == 0) {
    return true
  } else if (
    glnNumber.length !== 13 ||
    cleanEinvoiceField(glnNumber).length !== 13 ||
    invalidGlnExamples.includes(glnNumber)
  ) {
    return false
  }

  return true
}

const validateVat = (vatId: string, country: string) => {
  if (VATExamples.includes(vatId)) {
    return false
  }

  if (EUCountries.includes(country)) {
    for (const key in VATPatternsEU) {
      if (VATPatternsEU[key].test(vatId)) {
        return true
      }
    }
  } else if (["AU", "GB", "NZ"].includes(country)) {
    return VATPatterns[country].test(vatId)
  }

  return false
}

export const validateStreet = (
  streetName: string | null | undefined,
  deliveryMethod: string,
  houseNumber: boolean,
  country: string
) => {
  const letter = deliveryMethod === "0"
  const nonEuDhl =
    (deliveryMethod === "3" && !EUCountries.includes(country)) || (deliveryMethod === "8" && country === "GB")
  const postNord =
    deliveryMethod === "4" && ["DK", "AU", "CH", "EE", "ES", "FI", "FR", "GB", "IE", "IT", "US"].includes(country)

  if (streetName && streetName.length > 45 && nonEuDhl && !houseNumber) {
    return false
  } else if (streetName && streetName.length > 40 && nonEuDhl && houseNumber) {
    return false
  } else if (streetName && streetName.length > 50 && (postNord || letter) && !houseNumber) {
    return false
  } else if (streetName && streetName.length > 45 && (postNord || letter) && houseNumber) {
    return false
  } else return true
}

// make sure to also update the list in app/models/customer
const forbiddenCompanies = [
  "Mr",
  "Ms",
  "Mr.",
  "Ms.",
  "Mrs",
  "Mrs.",
  "Fru",
  "Fru.",
  "Herr",
  "Herr.",
  "Nej",
  "Nej.",
  "Privat",
  "Privat.",
  "No",
  "No.",
  "mr",
  "ms",
  "mr.",
  "ms.",
  "mrs",
  "mrs.",
  "fru",
  "fru.",
  "herr",
  "herr.",
  "nej",
  "nej.",
  "privat",
  "privat.",
  "no",
  "no.",
  "Frau",
  "nein",
  "Nein",
  "frau",
  "keine",
  "Keine",
  "n/a",
  "Nicht zutreffend",
  "*",
  "!",
  "”",
  '"',
  "#",
  ".",
  ",",
  "/",
  "?",
  "+",
  "-",
  "&",
  "%",
]

export default {
  vat_number_example: VATNumbers,
  email: EmailRegex,
  phone: PhoneRegex,
  shoppingItemType: ShoppingItemTypeRegex,
  link: LinkRegex,
  numberOrEmpty: NumberOrEmptyRegex,
  validateOrgID,
  validateGlnNumber,
  validateVat,
  cardYear: cardYearRegex,
  cardMonth: cardMonthRegex,
  cvc: cardCvcRegex,
  validateStreet,
  forbiddenCompanies,
}
