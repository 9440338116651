import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
  type Operation,
  InMemoryCache,
  type NormalizedCacheObject,
} from "@apollo/client"
import { type NetworkError } from "@apollo/client/errors"
import { onError } from "@apollo/client/link/error"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import { RetryLink } from "@apollo/client/link/retry"
import { type Reference, relayStylePagination } from "@apollo/client/utilities"
import { type TRelayPageInfo } from "@apollo/client/utilities/policies/pagination"
import { createUploadLink } from "apollo-upload-client"
import fetch from "cross-fetch"
import { sha256 } from "crypto-hash"
import { type OperationDefinitionNode } from "graphql"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { merge } from "lodash-es"
import React, { useMemo } from "react"
import generatedIntrospection from "./types.json"
import {
  BasketStateDocument,
  type BasketStateQuery,
  CheckoutStateDocument,
  type CheckoutStateQuery,
  type SetBasketStateMutationVariables,
} from "@/api/graphql"
import { getAdminSessionKey } from "@/common/auth"
import { consumer } from "@/common/cable"

declare global {
  interface Window {
    __APOLLO_STATES__?: NormalizedCacheObject[]
  }
}

declare module "@apollo/client" {
  export interface DefaultContext extends Record<string, any> {
    retry?: boolean
    hasUpload?: boolean
  }
}

export type CachedRelayEdge = {
  cursor?: string
  node: Reference
}

export type CachedRelayConnection = {
  edges?: CachedRelayEdge[]
  pageInfo?: TRelayPageInfo
}

const persistedQueriesLink = createPersistedQueryLink({ sha256 })

const retryNetworkError = (error: NetworkError) => {
  if (!error) {
    return false
  }

  // query/mutation aborted by AbortController
  if (error.name === "AbortError") {
    return false
  }

  console.error(`[Network error]: ${error}`)

  // Automatically retry if there was something wrong with the network, not for server errors.
  return (
    error.message.includes("Failed to fetch") ||
    error.message.includes("NetworkError when attempting to fetch") ||
    error.message.includes("cancelled")
  )
}

const linkWithRetry = (link: ApolloLink) => {
  return ApolloLink.from([
    persistedQueriesLink,
    new RetryLink({
      attempts: {
        retryIf(error, operation) {
          return operation.getContext().retry || retryNetworkError(error)
        },
      },
    }),
    link,
  ])
}

export interface CreateClientOptions {
  uri?: string
  sessionKey?: string | null
  locale?: string
  businessUser?: boolean
}

export const createClient = (opts?: CreateClientOptions) => {
  const headers: Record<string, string> = {}

  if (opts?.sessionKey) {
    headers["X-Admin-Session-Key"] = opts.sessionKey
  }

  if (opts?.locale) {
    headers["X-Skyltmax-Locale"] = opts.locale
  }

  if (opts?.businessUser) {
    headers["X-Skyltmax-Business-User"] = opts.businessUser ? "true" : "false"
  }

  const uri = opts?.uri || "/graphql"

  let link = linkWithRetry(
    createHttpLink({
      uri,
      credentials: "same-origin",
      headers,
      fetch,
    })
  )

  if (!import.meta.env.SSR) {
    link = ApolloLink.split(
      (operation: Operation) => operation.getContext().hasUpload || false,
      linkWithRetry(
        createUploadLink({
          uri,
          credentials: "same-origin",
          headers,
          fetch,
        })
      ),
      link
    )

    const hasSubscriptionOperation = ({ query: { definitions } }: Operation) => {
      return (definitions as OperationDefinitionNode[]).some(
        ({ kind, operation }) => kind === "OperationDefinition" && operation === "subscription"
      )
    }

    link = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({ cable: consumer }), link)
  }

  const initialStates = typeof window !== "undefined" ? window.__APOLLO_STATES__ || [] : []

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, forward, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.map(gqlError => {
            const { message, locations, path } = gqlError

            if (message === "login_required") {
              console.error("login_required")

              if (import.meta.env.SSR) {
                throw new Error("login_required")
              } else if (location.href.includes("admin.")) {
                location.reload()
              }

              return
            }

            console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          })

          return
        }

        return forward(operation)
      }),
      link,
    ]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: {
        Hash: {
          keyFields: false,
        },
        BasketState: {
          keyFields: false,
        },
        Query: {
          fields: {
            signs: relayStylePagination(),
            bundles: relayStylePagination(),
            customers: relayStylePagination(),
            orders: relayStylePagination(),
          },
        },
      },
    }).restore(
      merge(
        {
          ROOT_QUERY: {
            basketState: {
              open: false,
              addedNotice: false,
              reload: false,
              excelImportNotice: false,
              __typename: "BasketState",
            },
          },
        },
        ...initialStates
      )
    ),
    resolvers: {
      Mutation: {
        setBasketState: (_root, variables: SetBasketStateMutationVariables, context: { cache: InMemoryCache }) => {
          const data = context.cache.readQuery<BasketStateQuery>({ query: BasketStateDocument })

          variables.addedNotice = variables.addedNotice || false
          variables.excelImportNotice = variables.excelImportNotice || false
          variables.reload = variables.reload || false
          context.cache.writeQuery<BasketStateQuery>({
            query: BasketStateDocument,
            data: { basketState: { ...data?.basketState, ...variables } },
          })

          return null
        },
      },
    },
    ssrMode: import.meta.env.SSR,
    // ssrForceFetchDelay: 300,
  })

  return client
}

const defaultClient = createClient({ sessionKey: getAdminSessionKey() })

export interface ProviderProps {
  state?: {
    checkout?: CheckoutStateQuery
  }
  orderId?: number
}

interface ProviderRenderProps extends ProviderProps {
  children?: React.ReactNode
  client?: ApolloClient<any>
}

export const ApolloProviderPreloader = <TProps extends ProviderRenderProps>(props: TProps) => {
  const client = props.client || defaultClient

  useMemo(() => {
    if (props.state?.checkout && props.orderId) {
      client.writeQuery({
        query: CheckoutStateDocument,
        variables: { id: props.orderId.toString() },
        data: props.state.checkout,
      })
    }
  }, [props.orderId, props.state, client])

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export default defaultClient
