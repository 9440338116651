import EpicVite from "@skyltmax/epic_vite"

EpicVite.registerDynamic("AddBundleToCart", () => import("./add_bundle_to_cart").then(m => m.default))
EpicVite.registerDynamic("AddExampleToCart", () => import("./add_example_to_cart").then(m => m.default))
EpicVite.registerDynamic("Checkout", () => import("./checkout").then(m => m.default))
EpicVite.registerDynamic("CheckoutBasket", () => import("./checkout_basket").then(m => m.default))
EpicVite.registerDynamic("CookieChoices", () => import("./cookie_choices").then(m => m.default))
EpicVite.registerDynamic("CustomerZone", () => import("./customer_zone").then(m => m.default))
EpicVite.registerDynamic("ExampleInterstitial", () => import("./example_interstitial").then(m => m.default))
EpicVite.registerDynamic("Frontpage", () => import("./frontpage").then(m => m.default))
EpicVite.registerDynamic("HelpCenter", () => import("./help_center").then(m => m.default))
EpicVite.registerDynamic("HelpSearch", () => import("./help_search").then(m => m.default))
EpicVite.registerDynamic("InlineEdit", () => import("./inline_edit").then(m => m.default))
EpicVite.registerDynamic("NewsletterModal", () => import("./newsletter_modal").then(m => m.default))
EpicVite.registerDynamic("ProductFilter", () => import("./product_filter").then(m => m.default))
EpicVite.registerDynamic("Quote", () => import("./quote").then(m => m.default))
EpicVite.registerDynamic("CookieModal", () => import("./cookie_modal").then(m => m.default))
EpicVite.registerDynamic("Shopping", () => import("./shopping").then(m => m.default))
EpicVite.registerDynamic("Signomatic", () => import("./signomatic").then(m => m.default))
EpicVite.registerDynamic("SignsExample", () => import("./signs_example").then(m => m.default))
EpicVite.registerDynamic("SignsTagContent", () => import("./signs_tag_content").then(m => m.default))
EpicVite.registerDynamic("SignTagDesignPageBlock", () => import("./sign_tag_design_page_block").then(m => m.default))
EpicVite.registerDynamic("SignTagQuoteBlock", () => import("./sign_tag_quote_block").then(m => m.default))
EpicVite.registerDynamic("SiteMenu", () => import("./site_menu").then(m => m.default))
EpicVite.registerDynamic("Slideshow", () => import("./slideshow").then(m => m.default))
EpicVite.registerDynamic("Uploader", () => import("./uploader").then(m => m.default))
EpicVite.registerDynamic("SignTagExamples", () => import("./sign_tag_examples").then(m => m.default))
